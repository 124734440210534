import {scrollbar} from './scrollbar'

// Nav
const navCont = document.querySelector(".nav-container");
const myName = document.querySelector(".nav__name--init");
const navLinks = document.querySelector(".nav__links");
const hamburger = document.querySelector(".hamburger");
const hamburgerLines = document.querySelectorAll(".ham__lines");
const hamburgerSides = document.querySelectorAll(".ham__sides");
const linkArray = document.querySelectorAll(".nav__links .link");

/* __________ OPENS NAV __________ */

export function openNav() {
	navCont.classList.add("nav-container--show");
	// Hide Name
	myName.classList.add("nav__name--hide");

	hamburgerLines.forEach((line) => {
		line.classList.add("ham__lines--click");
		line.classList.remove("ham__lines");
	});
	hamburgerSides.forEach((line) => {
		line.classList.add("ham__sides--show");
	});
	navLinks.classList.add("nav__links--show");
	linkArray.forEach((link) => {
		link.classList.add("link--show");
	});
	hamburger.style.flexDirection = "row";
	hamburger.style.alignItems = "center";
	return true;
}
/* ------------------------ */

/* __________ CLOSES NAV __________ */

export function closeNav() {
	navCont.classList.remove("nav-container--show");
	// Remove name
	myName.classList.remove("nav__name--hide");

	hamburgerLines.forEach((line) => {
		line.classList.add("ham__lines");
		line.classList.remove("ham__lines--click");
	});
	hamburgerSides.forEach((line) => {
		line.classList.remove("ham__sides--show");
	});
	navLinks.classList.remove("nav__links--show");
	linkArray.forEach((link) => {
		link.classList.remove("link--show");
	});
	hamburger.style.alignItems = "stretch";
	hamburger.style.flexDirection = "column";
	return false;
}
/* -------------------------- */
