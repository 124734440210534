import { getHorizPosition } from "./utilities";

const projScroller = document.querySelector(".projects");
const projRightArr = document.querySelector(".right-arrow");
const projLeftArr = document.querySelector(".left-arrow");
const projArray = document.querySelectorAll(".projs");

/* ___________PROJECT VIEWER___________ */
let projCounter = 0;

projRightArr.addEventListener("click", () => {
	projScroller.scrollBy({
		behavior: "smooth",
		left: window.innerWidth,
	});
});

projLeftArr.addEventListener("click", () => {
	projScroller.scrollBy({
		behavior: "smooth",
		left: -window.innerWidth,
	});
});

/* -------------------------------------- */

/* ___________HIDE SCROLLBARS____________ */

const scroller = document.querySelector(".projects");
scroller.style.paddingBottom = scroller.offsetHeight - scroller.clientHeight + "px";
/* -------------------------------------- */

// /* _____________ Projects Scroll Function _____________ */

projScroller.addEventListener("scroll", scrollWatcher);

let lastScroll;
let scrollDirection;

function scrollWatcher(e) {
	// scrollbar.track.yAxis.hide();

	const currentScroll = scroller.scrollLeft;

	if (lastScroll < currentScroll) {
		scrollDirection = "right";
	} else {
		scrollDirection = "left";
	}

	/* _____________Projects Section_____________ */
	hideArrows(currentScroll);
	/* ------------------------------------------ */

	lastScroll = currentScroll;
}

/* ______________Hide Arrow Function______________ */

projLeftArr.classList.add("arrow--hide"); /* Initiates by hiding left arrow */
function hideArrows(currentScroll) {
	const startArrFade = getHorizPosition(projArray[0]) + window.innerWidth * 0.1;
	const endArrFade = getHorizPosition(projArray[projArray.length - 1]) - window.innerWidth * 0.1;
	if (currentScroll >= startArrFade) {
		projLeftArr.classList.remove("arrow--hide");
	} else {
		projLeftArr.classList.add("arrow--hide");
	}
	if (currentScroll <= endArrFade) {
		projRightArr.classList.remove("arrow--hide");
	} else {
		projRightArr.classList.add("arrow--hide");
	}
}
/* --------------------------------------------- */
