/* Gets position of given element */
export function getPosition(elem) {
	const top = document.querySelector("#home").getBoundingClientRect();
	const elemRect = elem.getBoundingClientRect();
	const position = elemRect.top - top.top;
	return position;
}

/* Gets horizontal position of given element */
export function getHorizPosition(elem) {
	const leftProj = document.querySelector(".projects__one").getBoundingClientRect();
	const elemRect = elem.getBoundingClientRect();
	const position = elemRect.left - leftProj.left;
	return position;
}
