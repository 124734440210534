import { openNav, closeNav } from "./nav";
import { getPosition } from "./utilities";

import {scrollbar} from './scrollbar'

/* __________ DOM Node Vars __________ */

// Overall Vars
const closeOverlays = document.querySelector(".close-overlays");
const overlays = document.querySelectorAll(".proj__overlay");

// Nav
const myName = document.querySelector(".nav__name--init");
const hamburger = document.querySelector(".hamburger");
const hamburgerLines = document.querySelectorAll(".ham__lines");
const home = document.querySelector(".links__home");
const project = document.querySelector(".links__projects");
const contact = document.querySelector(".links__contact");

// Home
const homeSection = document.querySelector("#home");
const homeBg = document.querySelector(".bg");

// Mid Vars
const midSection = document.querySelector(".mid-content");
const midText = document.querySelector(".mid-wrap__text");
const midImg = document.querySelector(".mid-wrap__image img");

// Project Section Vars
const projectsSection = document.querySelector(".entire-projects");
const entireProjects = document.querySelector(".proj-section-cont");
const projectsCont = document.querySelector(".projects");
const projArray = document.querySelectorAll(".projs .image img");

// Contact Section Vars
const contactSection = document.querySelector(".contact-section");
/* -------------------------- */

let hamClickFlag = false;

// Nav Hamburger Listener
hamburger.addEventListener("click", () => {
	if (!hamClickFlag) {
		hamClickFlag = openNav();
	} else {
		hamClickFlag = closeNav();
	}
});

/* _____________ NAV LINKS _____________ */

const scrollOptions = { block: "center", behavior: "smooth" };

home.addEventListener("click", () => {
	scrollbar.scrollTo(0, getPosition(homeSection), 600)
	navLinkClick();
	projectsCont.classList.remove("projects--scroll");
});
project.addEventListener("click", () => {
	scrollbar.scrollTo(0, getPosition(projectsSection), 600)
	navLinkClick();
	projectsCont.classList.remove("projects--scroll");
});
contact.addEventListener("click", () => {
	scrollbar.scrollTo(0, getPosition(contactSection), 600)
	navLinkClick();
	projectsCont.classList.add("projects--scroll");
});

function navLinkClick() {
	navLinkClickedFlag = true;
	const linkTimeout = setTimeout(() => {
		/* Allows ScrollTrigger events to fire when nav links finish scrolling */
		navLinkClickedFlag = false;
		/* Closes Nav on Mobile */
		ifMobileCloseNav();
		clearTimeout(linkTimeout);
	}, 500);
}

function ifMobileCloseNav() {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	if (width <= 600) {
		closeNav();
	}
}

//Fade Name on Scroll
let navLinkClickedFlag = false;
// let scrollTimeoutFlag = false;
let scrollDirection = "";
let lastScroll = 0;
let lastPosition;
const fadeNameAt = 55;

/* -------------------------- */

/* ___________ PROJECT OVERLAYS _____________ */

closeOverlays.addEventListener("click", () => {
	overlays.forEach((elem) => {
		elem.classList.remove("proj__overlay--show");
	});
	closeOverlays.classList.remove("close-overlays--show");
});

projArray[0].addEventListener("click", () => {
	overlays[0].classList.add("proj__overlay--show");
	closeOverlays.classList.add("close-overlays--show");
});

projArray[1].addEventListener("click", () => {
	overlays[1].classList.add("proj__overlay--show");
	closeOverlays.classList.add("close-overlays--show");
});

/* ------------------------------------------- */

// /* _____________ Main Scroll Function _____________ */
const scroller = document.querySelector(".snap-parent");
// scroller.addEventListener("scroll", scrollWatcher);
scrollbar.addListener(scrollWatcher)

function scrollWatcher(status) {
	const currentScroll = status.offset.y;

	if (lastScroll < currentScroll) {
		scrollDirection = "down";
	} else {
		scrollDirection = "up";
	}

	// Top of Page
	if (currentScroll <= fadeNameAt) {
		myName.classList.remove("nav__name--hide-scroll");
	} else {
		myName.classList.add("nav__name--hide-scroll");
	}

	// ---Fade Home---
	const fadeHomeAt = getPosition(homeSection) + window.innerHeight * 0.55;
	if (currentScroll <= fadeHomeAt) {
		homeBg.classList.add("bg--show");
	} else {
		homeBg.classList.remove("bg--show", "bg--show-init");
	}

	/* ____________Mid Content Section____________ */

	const startMidFade = getPosition(midSection) - window.innerHeight * 0.4;
	const endFadeMidAt = getPosition(midSection) + window.innerHeight * 0.4;
	if (currentScroll <= endFadeMidAt && currentScroll >= startMidFade) {
		midText.classList.add("mid-wrap__text--show");
	} else {
		midText.classList.remove("mid-wrap__text--show");
	}
	/* ------------------------------------------- */

	/* _________Change Hamburger Colors__________ */

	const midContentHam = getPosition(midSection) - hamburger.getBoundingClientRect().height / 2;
	const projectsHam = getPosition(projectsSection) - hamburger.getBoundingClientRect().height / 2;
	const contactHam = getPosition(contactSection) - hamburger.getBoundingClientRect().height / 2;
	if ((currentScroll >= midContentHam && currentScroll <= projectsHam) || currentScroll >= contactHam) {
		hamburgerLines.forEach((line) => {
			line.classList.add("ham__lines--show");
		});
	} else {
		hamburgerLines.forEach((line) => {
			line.classList.remove("ham__lines--show");
		});
	}
	/* -------------------------------------------- */

	/* _____________Projects Section_____________ */
	const startProjFade = getPosition(projectsSection) - window.innerHeight * 0.6;
	const endProjFade = getPosition(projectsSection) + window.innerHeight * 0.6;
	if (currentScroll <= endProjFade && currentScroll >= startProjFade) {
		entireProjects.classList.add("proj-section-cont--show");
	} else {
		entireProjects.classList.remove("proj-section-cont--show");
	}
	/* ------------------------------------------ */

	lastScroll = currentScroll;

	lastPosition = { x: 0, y: document.body.scrollTop };
}
