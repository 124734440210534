import "../style.scss";
import "./projects";
import "./app";
import "./project-videos";

const homeBg = document.querySelector(".bg");
const hamburger = document.querySelector(".hamburger");
const myName = document.querySelector(".nav__name--init");
const bgColor = document.querySelector(".bg-cont--init");

window.addEventListener("load", () => {
	homeBg.classList.add("bg--show-init");
	myName.classList.add("nav__name");
	hamburger.classList.add("hamburger--show");
	bgColor.classList.add("bg-cont");
});
