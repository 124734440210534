/* Truncates text while video is present */
const video = document.querySelector(".proj__video");
const closeOverlays = document.querySelector(".close-overlays");
const projOneText = document.querySelector(".overlay__subtext.one");
const projOneOriginalText = projOneText.innerText;
let seeMoreClickedFlag = false;

truncateText();

const seeMore = document.querySelector(".see-more");

/* ---------------------------------------- */

function seeMoreHandler() {
	if (!seeMoreClickedFlag) {
		hideVideo();
		seeMoreClickedFlag = true;
	} else {
		truncateText();
		seeMoreClickedFlag = false;
	}
}

function hideVideo() {
	const videoHeight = video.clientHeight;
	video.classList.add("proj__video--hide");
	video.style.height = videoHeight + "px";

	projOneText.classList.remove("overlay__subtext--truncate");
	projOneText.innerText = projOneOriginalText;
	projOneText.innerHTML += '<p class="see-more"><br>(Hide and watch video)</p>';
	const seeMore = document.querySelector(".see-more");
	seeMore.addEventListener("click", seeMoreHandler);
}

function truncateText() {
	let projOneTextShort = projOneText.innerText.split(" ");
	const maxNumberOfWords = 30;
	if (projOneTextShort.length > maxNumberOfWords) {
		/* Only appends "..." if over set limit */
		projOneTextShort = projOneTextShort.splice(0, maxNumberOfWords);
		projOneTextShort = projOneTextShort.join(" ") + "\u2026";
	}

	video.style.height = "auto";

	video.classList.remove("proj__video--hide");

	projOneText.classList.add("overlay__subtext--truncate");
	projOneText.innerText = projOneTextShort;
	projOneText.innerHTML += '<span class="see-more">(See more)</span>';
	const seeMore = document.querySelector(".see-more");
	seeMore.addEventListener("click", seeMoreHandler);
}

/* Pause when overlay closes */
closeOverlays.addEventListener("click", iFrameVideoPause);
function iFrameVideoPause() {
	video.contentWindow.postMessage('{"event":"command","func":"' + "pauseVideo" + '","args":""}', "*");
}
